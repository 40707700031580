<template>
  <div class="classManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='classManagement'">
        <div class="search_sList">
          <!-- <el-tabs v-model="activeName">
            <el-tab-pane
              label="专业数详情"
              name="first"
            ></el-tab-pane>
          </el-tabs> -->
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="formInline"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="班级标题">
                <el-input
                  v-model="formInline.name"
                  placeholder="班级标题"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="助学分类">
                <el-cascader
                  v-model="value"
                  :options="classData"
                  :props="props"
                  @change="handleChange"
                  filterable>
                </el-cascader>
              </el-form-item>
              <el-form-item label="发布状态">
                <el-select v-model="formInline.releaseStatus" placeholder="发布状态" filterable clearable>
                  <el-option label="未发布" value="1"></el-option>
                  <el-option label="已发布" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button
                class="mainBtn"
                @click="onSubmit"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetEvent" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="classEvent('add')" plain v-if="$isShowModle('tch_aid_class_add_edit')">添加班级</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
          >
            <el-table-column
              prop="name"
              label="班级标题"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name || '/' }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="助学分类"
              align="center"
              :formatter="regType"
            >
            </el-table-column>
            <el-table-column
              prop="artsSciences"
              label="文理属性"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.artsSciences">{{scope.row.artsSciences == 1 ? '理科' : '文科'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="releaseStatus"
              label="发布状态"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-switch
                  :disabled="!$isShowModle('tch_aid_class_publish')"
                  :active-value="2"
                  :inactive-value="1"
                  @change="chageReleaseStatus(scope.row)"
                  v-model="scope.row.releaseStatus">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              label="课件"
              align="center"
              width="60"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpCourse(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{ scope.row.coursewareCount || '0' }}</el-button>
                <span v-else>{{ scope.row.coursewareCount || '0' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="专项练习"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpSpecial(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.specialExercisesCount}}</el-button>
                <span v-else>{{scope.row.specialExercisesCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="真题精编"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpPaper(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.realPaperCount}}</el-button>
                <span v-else>{{scope.row.realPaperCount}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="mockPaperIds"
              label="模拟考试"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="jumpMockPaper(scope.row)" v-if="$isShowModle('tch_aid_class_resource')">{{scope.row.mockPaperCount}}</el-button>
                <span v-else>{{scope.row.mockPaperCount}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="学习人数"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="jumpStudyNum(scope.row)">{{scope.row.learnCount}}</el-button>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
              width="140"
            >
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime') || '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="170"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="classEvent('edit', scope.row)" v-if="$isShowModle('tch_aid_class_add_edit')">编辑</el-button>
                <el-button type="text" @click="jumpStudyNum(scope.row)">查看学生</el-button>
                <el-button type="text" @click="dialogDelDiyEvent(scope.row)" v-if="$isShowModle('tch_aid_class_del') && !scope.row.isGeneral">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changePage"
            :childMsg="childMsg"
          ></Pagination>
        </div>
        <Keydown @keydownEvent='handleKeydown'></Keydown>
        <dialogDiy :isShow="isShowClass" :dialogDiyData="dialogClassDiyData"
        @dialogEvent="dialogClassEvent">
          <div slot="dialogContent" class="class_area">
            <el-form :inline="true" :model="classForm" class="demo-form-inline" label-width="100px" :rules="rules">
              <el-form-item label="班级标题" prop="name">
                <el-input
                  v-model="classForm.name"
                  placeholder="班级标题"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="助学分类" prop="subjectCode" v-if="isShowAidClass">
                <el-cascader
                  v-model="value"
                  :options="classAidData"
                  :props="{value: 'assortValue', label: 'assortName', multiple: true}"
                  collapse-tags
                  @change="changeOpera"
                  filterable>
                </el-cascader>
              </el-form-item>
              <el-form-item label="文理属性" prop="name" v-if="classForm.eduCategory > 0">
                <el-select
                  v-model="classForm.artsSciences"
                  placeholder="全部"
                  filterable
                >
                  <el-option v-for="(item, key, index) in $common.liberalScience" :key="index" :label="item" :value="key"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button @click="dialogClassEvent">取消</el-button>
              <el-button type="primary" @click="enterEvent">确认</el-button>
            </div>
          </div>
        </dialogDiy>

        <dialogDiy
          :isShow="isShowDel"
          :dialogDiyData="dialogDelDiyData"
          @dialogEvent="isShowDel = false"
        >
          <div slot="dialogContent" class="dia_opera">
            <img class="errIcon" src="@/assets/img/warn.png" alt="" />
            <p>确定要删除该班级详情吗？</p>
            <div class="btn_area">
              <el-button @click="isShowDel = false">关闭</el-button>
              <el-button class="mainBtn" @click="doEvent">确定</el-button>
            </div>
          </div>
        </dialogDiy>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
import Pagination from '../Pagination'
import publicChildren from '../publicChildren'
import dialogDiy from '@/components/commonModule/dialogDiy'
export default {
  name: 'classManagement',
  components: {
    Pagination,
    publicChildren,
    dialogDiy
  },
  data() {
    let that = this;
    async function lazyLoad(node, resolve) {
      const { level } = node;
      if(level == '0') return;
      else if(level == 1) {
        that.classOptionForm.type = 1;
        that.classOptionForm.eduCategory = null;
      } else if(level == 2) {
        that.classOptionForm.eduCategory = node.value;
      }
      let nodes = await that.getAidClassificationData();
      nodes.forEach(item => item.leaf = level >= 2)
      setTimeout(() => {
        resolve(nodes);
      }, 600);
    }
    return {
      value: [],
      publishStatus: [],
      classOptionForm: {
        eduCategory: null,
        type: null
      },
      formInline: {
        artsSciences: null,
        eduCategory: null,
        name: null,
        page: 1,
        pageSize: 10,
        releaseStatus: null,
        subjectCode: null,
        type: null
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      value: [],
      isShowClass: false,
      dialogClassDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '300px'}
      },
      classOptionFormNew: {
        eduCategory: null,
        type: null
      },
      classForm: {
        artsSciences: null,
        name: null,
        eduCategory: null,
        id: null,
        subjectCode: null,
        type: null,
        isGeneral: false
      },
      classAidData: [],
      rules: {
        name: [
          { required: true, message: "请输入班级标题", trigger: "blur" }
        ],
        subjectCode: [
          { required: true, message: "请选择助学分类", trigger: "blur" }
        ],
      },
      type: null,
      isShowDel: false,
      dialogDelDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
      classData: [],
      props: {
        lazy: true,
        value: 'assortValue',
        label: 'assortName',
        lazyLoad
      },
      isShowAidClass: true,
      eduFlag: null,
      classCopyAidData: []
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.name == "classManagement") {
          this.clearClassOptionForm();
          this.clearClassOptionFormNew();
          // this.getQuery(newVal)
          this.getAidClassManagementData()
          this.loopEvent()
          this.getAidClassificationData().then(res=>{
            this.classData = res;
          });
        }
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    handleKeydown(event) {
      if (!this.isShowClass&&!this.isShowDel) {
        this.onSubmit()
      }
    },
    getQuery(newVal) {
      // this.formInline.type = newVal.query.type;
      // this.formInline.eduCategory = newVal.query.eduCategory;
      // this.formInline.subjectCode = newVal.query.subjectCode;
      // this.classForm.type = newVal.query.type;
      // this.classForm.eduCategory = newVal.query.eduCategory;
      // this.classForm.subjectCode = newVal.query.subjectCode;
    },
    clearClassOptionForm() {
      this.classOptionForm = {
        eduCategory: null,
        type: null
      }
    },
    clearClassOptionFormNew() {
      this.classOptionFormNew = {
        eduCategory: null,
        type: null
      }
    },
    getAidClassificationData() {
      return new Promise((resolve, reject) => {
        this.$request.aidClassificationData(this.classOptionForm).then(res => {
          if(res.data.code == 0){
            res.data.data.forEach((item, index) => {
              item.hasChildren = true
              item.id = item.assortValue
              item.label = 1
            })
            resolve(res.data.data);
          }
        })
      })
    },
    getAidClassificationDataNew() {
      return new Promise((resolve, reject) => {
        this.$request.aidClassificationData(this.classOptionFormNew).then(res => {
          if(res.data.code == 0){
            // res.data.data.forEach((item, index) => {
            //   item.hasChildren = true
            //   item.id = item.assortValue
            //   item.label = 1
            // })
            resolve(res.data.data);
          }
        })
      })
    },
    // 遍历助学分类所有数据
    async loopEvent() {
      let aidData = await this.getAidClassificationDataNew()
      this.classOptionFormNew.type = 1
      aidData[0].children = await this.getAidClassificationDataNew()
      aidData[0].children.push({
        assortName: '专升本通用',
        assortValue: '11'
      })
      for (let i = 0; i < 3; i++) {
        this.classOptionFormNew.eduCategory = i
        aidData[0].children[i].children = await this.getAidClassificationDataNew()
      }
      let newAid = JSON.parse(JSON.stringify(aidData))
      newAid[0].children.forEach((item, index) => {
        if(item.assortValue > 0) delete item.children
      })
      // console.log(aidData,8686)
      this.classCopyAidData = aidData
      this.classAidData = newAid
    },
    // 搜索
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getAidClassManagementData();
    },
    // 重置
    resetEvent() {
      this.formInline.eduCategory = null
      this.formInline.name = null
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.releaseStatus = null
      this.formInline.subjectCode = null
      this.value = null
    },
    regType(row) {
      let typeVal = row.type == 1 ? '成考考前辅导' : ''
      let eduVal = row.eduCategory == '0' ? '专升本' : row.eduCategory == 1 ? '高起本' : '高起专'
      let val = row.isGeneral || row.eduCategory != '0' ? `${typeVal}/${eduVal}` : `${typeVal}/${eduVal}/${row.subjectName}`
      return val;
    },
    // 获取班级表格数据
    getAidClassManagementData() {
      let obj = this.formInline;
      this.$request.aidClassManagementData(obj).then(res => {
        if (res.data.code == 0) {
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getAidClassManagementData();
    },
    dialogClassEvent() {
      this.isShowClass = !this.isShowClass;
    },
    classEvent(type, row) {
      this.type = type;
      this.dialogClassDiyData.title = type == 'add' ? '添加班级' : '编辑班级'
      // console.log(row,666)
      if(type == 'edit' && row.releaseStatus == 2) {
        this.dialogClassDiyData.sizeStyle.height = '280px'
      } else if(type == 'edit' && row.releaseStatus == 1 && row.eduCategory > 0) {
        this.dialogClassDiyData.sizeStyle.height = '380px'
      } else if(type == 'edit' && row.releaseStatus == 1 && row.eduCategory == 0) {
        this.dialogClassDiyData.sizeStyle.height = '300px'
      }
      this.isShowAidClass = type == 'edit' && row.releaseStatus == 2 ? false : true;
      this.classForm.id = type == 'add' ? null : row.id;
      this.classForm.name = type == 'add' ? null : row.name;
      this.classForm.isGeneral = type == 'add' ? false : row.isGeneral;
      this.classForm.type = type == 'add' ? null : row.type
      this.classForm.eduCategory = type == 'add' || row.isGeneral ? null : row.eduCategory
      this.classForm.subjectCode = type == 'add' || row.isGeneral ? null : row.subjectCode
      this.classForm.artsSciences = type == 'add' || !row.artsSciences ? null : String(row.artsSciences)
      this.value = []
      if(type == 'edit' && row.isGeneral) {
        this.value = [[String(row.type), '11']]
      } else if(type == 'edit' && row.eduCategory == 0) {
        row.subjectCode.split(',').forEach(item => {
          this.value.push([String(row.type), String(row.eduCategory), String(item)])
        })
      } else if(type == 'edit' && row.eduCategory > 0) {
        this.value.push([String(row.type), String(row.eduCategory)])
      }
      // console.log(this.value)
      this.dialogClassEvent()
    },
    enterEvent() {
      if(this.type == 'edit') {
        if(!this.classForm.name) {
          this.Warn('班级标题未填');
          return;
        }
      } else {
        if(!this.classForm.name) {
          this.Warn('班级标题未填');
          return;
        }
        if(!this.classForm.isGeneral && !this.classForm.subjectCode) {
          this.Warn('助学分类未填');
          return;
        }
        if(!this.classForm.artsSciences && this.classForm.eduCategory != '0') {
          this.Warn('文理属性未选择');
          return;
        }
      }
      this.$request.changeClass(this.classForm).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.clearEvent();
          this.getAidClassManagementData();
          this.dialogClassEvent();
        }
      });
    },
    clearEvent() {
      Object.assign(this.$data.classForm, this.$options.data().classForm)
    },
    chageReleaseStatus(row) {
      let obj = {
        id: row.id,
        status: row.releaseStatus
      }
      this.$request.publishClass(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
        } else if(res.data.code == 1) {
          row.releaseStatus = row.releaseStatus == 1 ? 2 : 1
        }
      }).catch(err => {
        row.releaseStatus = row.releaseStatus == 1 ? 2 : 1
      })
    },
    handleChange(val) {
      this.formInline.type = val[0]
      this.formInline.eduCategory = val[1]
      this.formInline.subjectCode = val[2]
    },
    changeOpera(val) {
      if(val.length == 0) {
        this.clears()
        return;
      }
      let arr = []
      if(val.length == 1 || (this.eduFlag == null && val.length > 0)) {
        this.eduFlag = val[0][1]
      }
      let index = val.findIndex(item => item[1] != this.eduFlag)
      if(index != -1 && val[index][1] == 0) {
        let flag = this.eduFlag
        this.eduFlag = val[index][1]
        this.value = []
        val.forEach(ite => {
          if(flag != ite[1]) this.value.push(ite)
        })
      } else if(index != -1 && val[index][1] > 0) {
        this.eduFlag = val[index][1]
        this.value = [val[index]]
      }
      // console.log(this.eduFlag,'eduFlag')
      if(this.eduFlag == 0) {
        this.value.forEach((item, index) => {
          arr.push(item[2])
        })
      } else if(this.eduFlag > 0 && this.eduFlag != 11) {
        // console.log(this.classCopyAidData[0].children)
        this.classCopyAidData[0].children.forEach(item => {
          if(item.assortValue == this.eduFlag) {
            item.children.forEach(i => arr.push(i.assortValue))
          }
        })
      }
      if(this.eduFlag == 11) {
        // 选中专升本通用的时候执行该函数方法，下面的赋值就不执行了
        this.getNewData()
        return;
      }
      // console.log(this.value,77777777)
      // console.log(this.classForm,5222)
      this.classForm.isGeneral = false
      this.classForm.type = this.value[0][0]
      this.classForm.eduCategory = this.value[0][1]
      this.classForm.subjectCode = arr.join(',')
      if(this.classForm.eduCategory > 0) this.dialogClassDiyData.sizeStyle.height = '360px'
      else this.dialogClassDiyData.sizeStyle.height = '300px'
    },
    // 选择专升本通用下的classForm赋值逻辑
    getNewData() {
      let name = this.classForm.name
      Object.assign(this.$data.classForm, this.$options.data().classForm)
      this.classForm.name = name
      this.classForm.eduCategory = 0
      this.classForm.isGeneral = true
      this.classForm.type = 1
      this.dialogClassDiyData.sizeStyle.height = '300px'
    },
    clears() {
      let name = this.classForm.name
      let id = this.classForm.id
      Object.assign(this.$data.classForm, this.$options.data().classForm)
      this.classForm.name = name
      this.classForm.id = id
      this.dialogClassDiyData.sizeStyle.height = '300px'
    },
    dialogDelDiyEvent(row) {
      this.rowData = row;
      this.isShowDel = !this.isShowDel;
    },
    doEvent() {
      this.$request.deleteAidClass({id: this.rowData.id}).then(res => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.getAidClassManagementData();
          this.isShowDel = !this.isShowDel;
        }
      });
    },
    jumpStudyNum(row) {
      this.$router.push({name: 'classStudyNumDetails', query: {
        eduCategory: row.eduCategory,
        subjectCode: row.subjectCode,
        artsSciences: row.artsSciences,
        aidClassId: row.id
      }})
    },
    jumpCourse(row) {
      this.$router.push({name: 'coursewareDetails', query: {
        aidClassId: row.id
      }})
    },
    jumpSpecial(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'specialExercises', query: {
        aidClassId: row.id,
        // ids: row.specialExercisesIds
      }})
    },
    jumpPaper(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'refinedEdition', query: {
        aidClassId: row.id,
        // ids: row.realPaperIds,
        year: row.year,
        type: 3,
        headTitle: '真题精编详情'
      }})
    },
    jumpMockPaper(row) {
      sessionStorage.setItem('fatherRoute', this.$route.fullPath)
      this.$router.push({name: 'refinedEdition', query: {
        aidClassId: row.id,
        // ids: row.mockPaperIds,
        type: 1,
        headTitle: '模拟考试详情'
      }})
    },
  },
}
</script>

<style lang='less'>
  .classManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .class_area {
      height: calc(~'100% - 70px');
      box-sizing: border-box;
      padding: 0px 20px;
      position: relative;
      .demo-form-inline {
        padding-top: 20px;
      }
      .btn_area {
        text-align: right;
        padding: 20px 0px;
        position: absolute;
        bottom: 0;
        right: 20px;
      }
    }
  }
</style>
<style lang="less" scoped>
  .dia_opera {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 30px;
    height: 100%;
    .errIcon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  }
</style>